.ct-footer {
  width: 100%;
  margin-top: 300px;
  padding-top: 100px;
  padding-bottom: 100px;
  box-shadow: 0px -5px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  background: #098efc;
}

.ct-footer > .ct-big-info-container {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ct-footer > .ct-big-info-container > .ct-big-info {
  margin: 15px;
  display: flex;
}

.ct-footer > .ct-big-info-container > .ct-big-info > h4 {
  width: 100%;
  margin-bottom: 15px;
  font-size: 14px;
  color: white;
}

.ct-footer > .ct-big-info-container > .ct-big-info > a > span {
  margin-left: 7px;
  font-size: 25px;
  color: white;
}

.ct-footer > .ct-big-info-container > .ct-big-info > a {
  color: black;
  text-decoration: none;
  font-size: 25px;
  color: white;
  margin: 5px;
}

.ct-footer > .ct-big-info-container > .ct-links {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.ct-footer > .ct-big-info-container > .ct-links > a,
.ct-footer > .ct-big-info-container > .ct-links > span {
  color: white;
  font-size: 12px;
}

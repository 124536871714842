.ct-top-nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  z-index: 9999;
  background: transparent;
}

.ct-top-nav.ct-focused-navigation {
  background: #098efc !important;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%) !important;
}

.ct-top-nav > .ct-max-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.ct-top-nav > .ct-max-container > .ct-title {
  padding: 0 20px 0 20px;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.ct-top-nav > .ct-max-container > .ct-title > img {
  width: 40px;
  margin-right: 6px;
}

.ct-top-nav > .ct-max-container > .ct-title > h1 {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: white;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ct-top-nav > .ct-max-container > .ct-links {
  display: flex;
  padding-right: 20px;
  color: black;
  align-items: center;
}

.ct-top-nav > .ct-max-container > .ct-links a {
  color: white;
  text-decoration: none;
  height: 40px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: lighter;
  font-size: 12px;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}

.ct-chain-selector-wrapper {
  outline: none;
}

#select-blockchain {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
}

#select-blockchain > span {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
}

.ct-chain-selector-wrapper select,
.ct-chain-selector-wrapper select:active,
.ct-chain-selector-wrapper select:focus,
.ct-chain-selector-wrapper select:focus-visible,
.ct-chain-selector-wrapper div:focus-visible,
.ct-chain-selector-wrapper fieldset,
.ct-chain-selector-wrapper select:hover {
  outline: none !important;
  border: 0;
  border-radius: 0;
  border-bottom: 0px solid rgba(2, 126, 251, 1) !important;
}

.ct-chain .ct-chain-icon,
.ct-chain-selector .ct-chain-icon {
  height: 18px;
  width: 18px;
  margin-right: 7px;
}

.ct-top-nav > .ct-max-container > .ct-links > .ct-data {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ct-top-nav > .ct-max-container > .ct-links > div.ct-address {
  font-weight: lighter;
  width: 100px;
  text-align: center;
  margin-left: 20px;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-balance {
  font-size: 9px;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: right;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-balance > a {
  font-size: 11px;
  margin-right: 7px;
  cursor: pointer;
  color: black;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-address {
  text-overflow: ellipsis;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  direction: rtl;
  font-size: 12px;
  font-weight: bold;
  text-align: right;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-actions {
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-actions > span,
.ct-top-nav > .ct-max-container > .ct-links .ct-actions > a {
  cursor: pointer;
  margin-left: 5px;
  font-size: 16px;
}

@media screen and (min-width: 500px) {
  .ct-top-nav > .ct-max-container > .ct-links .ct-balance {
    font-size: 12px;
  }

  .ct-top-nav > .ct-max-container > .ct-links a {
    font-size: 12px;
  }

  .ct-top-nav > .ct-max-container > .ct-links .ct-balance > a {
    font-size: 12px;
  }

  .ct-top-nav > .ct-max-container > .ct-links .ct-address {
    font-size: 12px;
  }
}

@media screen and (min-width: 1600px) {
  .ct-top-nav > .ct-max-container > .ct-links .ct-actions .ct-left-toggler {
    display: none;
  }
}

.ct-blockchain-error {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.671);
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ct-blockchain-error > div {
  background: white;
  padding: 20px;
  text-align: center;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  width: 90%;
  max-width: 400px;
  border-radius: 10px;
}

.ct-blockchain-error > div > h4 {
  font-size: 25px;
}

.ct-blockchain-error > div .ct-suggested-networks,
.ct-blockchain-error > div .ct-suggested-providers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.ct-blockchain-error > div .ct-suggested-networks > div.ct-chain-container,
.ct-blockchain-error > div .ct-suggested-providers > div.ct-provider-container {
  width: 100px;
  height: 100px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  cursor: pointer;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ct-blockchain-error > div .ct-suggested-networks > div.ct-chain-container > img,
.ct-blockchain-error > div .ct-suggested-providers > div.ct-provider-container > img {
  width: 60%;
  margin-bottom: 10px;
}

.ct-blockchain-error > div .ct-suggested-networks > div.ct-chain-container:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ocean3-inverted {
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: -200px;
  left: 0;
  background: transparent;
  transform: rotateX(180deg);
  overflow: hidden;
}

.wave3-inverted {
  transform: rotateX(180deg);
  background: #0189f8;
  z-index: 99;
  top: 20px;
  background: url(https://cdn.kcak11.com/codepen_assets/wave_animation/wave.svg) repeat-x;
  position: absolute;
  width: 6400px;
  height: 198px;
  animation: wave 50s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  -webkit-filter: grayscale(100%) invert(45%) sepia(84%) saturate(4656%) hue-rotate(189deg) brightness(103%) contrast(99%);
  filter: grayscale(100%) invert(45%) sepia(84%) saturate(4656%) hue-rotate(189deg) brightness(103%) contrast(99%);
}

.wave3-inverted:nth-of-type(2) {
  animation: wave 50s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

.endWave {
  display: none;
}

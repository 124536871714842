.ct-dynamic-pool-container .ct-charts > div {
  height: 200px;
  padding: 10px 10px 30px 10px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  border-radius: 5px;
  margin-top: 10px;
}

.ct-dynamic-pool-container .ct-charts > div > h4 {
  font-size: 14px;
  font-weight: 400;
}

.ct-testnet-pool {
  color: red;
  font-size: 14px;
}

.ct-pool-benefits {
  font-size: 14px;
  margin-bottom: 20px;
}

.ct-pool-benefits > ul {
  margin-left: 15px;
}

.ct-dynamic-pool-container > h4 {
  font-size: 32px;
  font-weight: 400;
}

.ct-dynamic-pool-container > p:nth-child(2) {
  font-size: 16px;
  margin-top: -5px;
  font-weight: bolder;
  margin-bottom: 10px;
}

.ct-dynamic-pool-container > div:nth-child(3) {
  font-size: 12px;
  margin-bottom: 20px;
}

.ct-dynamic-pool-container > div:nth-child(3) > p {
  margin-bottom: 7px;
}

.ct-dynamic-pool-container p.ct-days-to-fill {
  font-size: 14px;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
}

.ct-dynamic-pool-container p.ct-days-to-fill > small {
  list-style: circle;
  display: list-item;
  margin-left: 15px;
}

.ct-dynamic-pool-container .ct-progress-bar-container {
  width: 100%;
  background: grey;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border-radius: 5px;
}

.ct-dynamic-pool-container .ct-progress-bar-container > div {
  background: rgba(0, 238, 52, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 5px;
}

.ct-dynamic-pool-container .ct-progress-bar-container > span {
  font-size: 12px;
  color: white;
}

.ct-dynamic-pool-container .ct-investor-container > h4 {
  font-size: 25px;
  font-weight: 400;
}

.ct-dynamic-pool-container .ct-investor-container > small {
  font-weight: bold;
}

.ct-dynamic-pool-container .ct-investor-container > ul > li {
  font-size: 12px;
  margin-left: 15px;
}

.ct-dynamic-pool-container .ct-investor-container > .ct-investor-data {
  margin-top: 10px;
}

.ct-dynamic-pool-container .ct-investor-container > .ct-investor-data > ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.ct-dynamic-pool-container .ct-investor-container > .ct-investor-data > ul > li {
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  margin: 10px;
  width: 90%;
  max-width: 200px;
  padding: 0 15px;
  background: rgb(0, 172, 238);
  color: white;
  font-weight: bolder;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}

.ct-connect-to-network {
  margin: 20px 0;
  max-width: 300px;
}

.ct-connect-to-network > h4 {
  font-size: 24px;
  font-weight: 400;
}

.ct-connect-to-network > p {
  font-size: 12px;
  margin-bottom: 10px;
}

/* Pool injections */

.ct-dynamic-injected-transactions > h4 {
  margin-bottom: 15px;
}

.ct-dynamic-injected-transactions {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ct-dynamic-injected-transactions > div {
  display: flex;
  border-bottom: 1px solid rgba(128, 128, 128, 0.466);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  padding: 5px 0;
  align-items: center;
  margin-bottom: 10px;
  background: white;
  position: relative;
}

.ct-dynamic-injected-transactions > div > .ct-type {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  flex: 0 0 15%;
  min-width: 70px;
  height: 100%;
}

.ct-dynamic-injected-transactions > div > .ct-type.ct-injection-type {
  background: rgba(123, 247, 123, 0.616);
}

.ct-dynamic-injected-transactions > div > div {
  margin: 7px;
  font-weight: lighter;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}

.ct-dynamic-injected-transactions > div > .ct-data {
  flex: 0 0 70%;
  min-width: 160px;
  align-items: flex-start;
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
}

.ct-dynamic-injected-transactions > div > .ct-actions {
  flex: 0 0 15%;
  min-width: 70px;
}

.ct-slider-item {
  height: 350px;
  width: 100vw;
  max-width: 400px;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin: 10px;
  margin-right: 30px;
  margin-bottom: 50px;
}

.ct-slider-item:hover {
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}

.ct-slider-item .imageContainer {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ct-slider-item .infoContainer {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.ct-slider-item .infoContainer .overlay {
  background-color: rgba(9, 144, 240, 0.89);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ct-slider-item .infoContainer p {
  color: white;
  z-index: 10;
  padding: 10px;
  font-size: 12px;
}

.ct-slider-item .titleItem {
  color: white;
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.438);
  position: absolute;
  top: -30px;
  left: 10px;
  font-weight: bolder;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}

.ct-slider-item .infoContainer .progressBarContainer {
  text-align: center;
  height: 15px;
  width: 100%;
  background-color: rgb(250, 253, 249);
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
}

.ct-slider-item .progressBarContainer .progressBar {
  height: 100%;
  background-color: rgb(27, 219, 210);
}

.ct-slider-item .bottomContainer {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-slider-item .button {
  margin-top: auto;
  margin-bottom: auto;
}

.ct-slider-item .goalContainer {
  width: 50%;
  height: 100%;
}

.ct-slider-item .goal1 {
  padding-top: 3px;
  width: 100%;
  height: 40%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  text-shadow: 1px 1px 2px rgb(19, 19, 19);
}

.ct-slider-item .goal2 {
  margin-top: -5px;
  width: 100%;
  height: 60%;
  text-align: center;
  color: white;
  font-size: 26px;
  text-shadow: 1px 1px 2px rgb(19, 19, 19);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-slider-item .icon {
  margin-bottom: 1.7%;
  width: 20%;
  padding-right: 8px;
  font-size: 18px;
  color: white;
  text-shadow: 1px 1px 2px rgb(19, 19, 19);
}

.ct-slider-item .icon2 {
  font-size: 15px;
  color: white;
}

.ct-slider-item.ct-active-slider {
  border: 4px solid white;
  box-shadow: 0 19px 38px rgba(255, 255, 255, 0.3), 0 15px 12px rgba(255, 255, 255, 0.22);
}

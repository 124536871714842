@import url("https://fonts.googleapis.com/css2?family=Bangers&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Material+Icons&family=Anton&family=Permanent+Marker&family=Roboto+Slab:wght@300;400;500;600;700&family=VT323&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,600;0,900;0,1000;1,200;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Oswald:wght@200;300;400;500;600;700&display=swap");

html {
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}
ct-circles-animation body {
  max-width: 100vw;
  background: rgb(255, 255, 255);
  overflow-x: hidden;
}

.ct-app-container {
  width: 100%;
  position: relative;
}

.ct-max-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Buttons                               */
/* -------------------------------------------------------------------------- */

.animate__delay-0-5s {
  animation-delay: 0.5s;
}

.animate__delay-1-5s {
  animation-delay: 1.5s;
}

.animate__delay-2-5s {
  animation-delay: 2.5s;
}

.animate__delay-3-5s {
  animation-delay: 3.5s;
}

.ct-main-button {
  transition: all 0.2s ease;
  background: black;
  border: 0;
  color: white;
  padding: 0 15px;
  font-weight: bold;
  cursor: pointer;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  height: 30px;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 10px;
}

.ct-main-button:disabled:hover {
  background: #ef9a9a;
}

.ct-main-button.ct-active {
  background: #7e7e7e;
}

.ct-main-button:hover {
  background: #7e7e7e;
}

.btn-2 {
  background: rgb(0, 172, 238);
  text-transform: uppercase;
  height: 30px;
  width: 100%;
  margin-top: 5px;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Margins                               */
/* -------------------------------------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
}

.ct-mb-1 {
  margin-bottom: 1px;
}

.ct-mb-2 {
  margin-bottom: 2px;
}

.ct-mb-20 {
  margin-bottom: 20px !important;
}

.ct-mb-5 {
  margin-bottom: 5px;
}

.ct-pt-30 {
  padding-top: 30px;
}

.ct-pt-20 {
  padding-top: 20px;
}

.ct-pb-10 {
  padding-bottom: 10px;
}

.ct-mt-5 {
  margin-top: 5px;
}

.ct-mt-10 {
  margin-top: 10px;
}

.ct-mt-20 {
  margin-top: 20px;
}

.ct-mt-50 {
  margin-top: 50px;
}

.ct-mr-5 {
  margin-right: 5px !important;
}

.ct-mr-10 {
  margin-right: 10px !important;
}

.ct-ml-5 {
  margin-left: 5px !important;
}

.ct-ml-10 {
  margin-left: 10px !important;
}

a {
  font-weight: bold;
  color: black;
}

.ct-title {
  display: flex;
  flex-direction: column;
}

.ct-title > span:nth-child(2) {
  font-size: 10px;
  font-weight: bold;
}

input {
  text-transform: uppercase;
  font-size: 10px;
}

input,
input:hover,
input:active,
input:focus {
  outline: none;
}

textarea,
textarea:focus,
textarea:active {
  border: 0;
  border-bottom: 1px solid black;
  outline: none;
  padding: 10px;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Comming                               */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Spinners                               */
/* -------------------------------------------------------------------------- */

.spinner {
  width: 40px;
  height: 40px;
  background-color: #333;
  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.Toastify {
  z-index: 999999999999 !important;
}

.Toastify__toast {
  z-index: 999999999999 !important;
}

/* -------------------------------------------------------------------------- */
/*                            ANCHOR Universal Minter                         */
/* -------------------------------------------------------------------------- */

button {
  background-color: black;
  color: white;
  border: 0;
  border-radius: 3px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  height: 25px;
}

button:disabled {
  background: rgb(211, 89, 89);
}

.ct-page-header {
  position: relative;
  margin-bottom: 20px;
}

input {
  border: 0;
  border-bottom: 1px solid black;
  height: 30px;
  text-align: center;
  background: transparent;
}

input:focus,
input:active,
input:hover {
  background-color: transparent;
}

/* Material UI */

.MuiSlider-colorSecondary {
  color: black;
}

/* Main web page */

.ct-platforms-banner,
.ct-info-container {
  display: flex;
  justify-content: center;
  color: rgb(255, 255, 255);
  align-items: center;
  flex-direction: row;
  padding: 50px;
  margin-bottom: 50px;
  flex-wrap: wrap;
}

.ct-platforms-banner > .ct-info,
.ct-info-container > .ct-info {
  max-width: 300px;
  margin: 10px;
  padding: 10px;
}

.ct-platforms-banner > .ct-info > h4,
.ct-info-container > .ct-info > h4 {
  font-size: 30px;
  font-family: "Plaguard";
  margin-bottom: 10px;
}

.ct-platforms-banner > .ct-info > p a {
  color: white;
}

.ct-platforms-banner > .ct-info > div,
.ct-info-container > .ct-info > div {
  display: flex;
  margin-top: 15px;
  flex-direction: row;
}

.ct-platforms-banner > .ct-info > div > span,
.ct-info-container > .ct-info > div > span {
  font-size: 30px;
  margin: 0 10px;
  width: 40px;
  height: 40px;
}

.ct-platforms-banner > .ct-info > div > img,
.ct-info-container > .ct-info > div > img {
  width: 40px;
  height: 40px;
}

.ct-platforms-banner > .ct-info > button,
.ct-info-container > .ct-info > button {
  margin-top: 15px;
  max-width: 200px;
}

.ct-cards-show {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ct-cards-show .ct-header {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 10px;
  width: 90%;
}

.ct-cards-show .ct-header h4 {
  font-family: "Plaguard";
  font-size: 40px;
}

.ct-cards-show .ct-header small {
  font-size: 15px;
}

.ct-cards-show .ct-deck-container {
  margin-bottom: 50px;
}

/* Profile page */

.ct-profile-page {
  padding-top: 50px;
}

/* Principal Button */

.btn-3 {
  color: #fff;
  background: rgb(0, 172, 238);
  background: linear-gradient(0deg, rgba(0, 172, 238, 1) 0%, rgba(2, 126, 251, 1) 100%);
  width: 130px;
  height: 40px;
  line-height: 42px;
  padding: 0;
  border: none;
  font-weight: 500;
  font-family: "Lato", sans-serif;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5), 7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
  text-shadow: 1px 1px 2px rgb(19 19 19);
  text-transform: uppercase;
}
.btn-3 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-3:before,
.btn-3:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background: rgba(2, 126, 251, 1);
  transition: all 0.3s ease;
}
.btn-3:before {
  height: 0%;
  width: 2px;
}
.btn-3:after {
  width: 0%;
  height: 2px;
}
.btn-3:hover {
  background: transparent;
  box-shadow: none;
}
.btn-3:hover:before {
  height: 100%;
}
.btn-3:hover:after {
  width: 100%;
}
.btn-3 span:hover {
  color: white;
}
.btn-3 span:before,
.btn-3 span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: rgba(2, 126, 251, 1);
  transition: all 0.3s ease;
}
.btn-3 span:before {
  width: 2px;
  height: 0%;
}
.btn-3 span:after {
  width: 0%;
  height: 2px;
}
.btn-3 span:hover:before {
  height: 100%;
}
.btn-3 span:hover:after {
  width: 100%;
}

.ct-test-container {
  height: 500px;
}

/* pools page */

.ct-pool-page .ct-available-pools {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgba(250, 250, 250, 0.699);
  padding-bottom: 40px;
}

.ct-pool-page .ct-available-pools > .ct-header {
  display: flex;
  flex-direction: column;
}

.ct-pool-page .ct-available-pools > .ct-header h4 {
  font-size: 25px;
  margin-bottom: -3px;
  font-weight: 400;
}

.ct-pool-page .ct-available-pools > .ct-pools-list {
  margin-top: 20px;
}

.ct-blue-gradient-top {
  background: rgb(17, 88, 166);
  background: linear-gradient(180deg, rgba(17, 88, 166, 0) 0%, rgba(9, 142, 240, 1) 66%);
  height: 400px;
}

.ct-blue-gradient-bottom {
  background: rgb(17, 88, 166);
  background: linear-gradient(0deg, rgba(118, 123, 128, 0) 0%, rgba(9, 142, 240, 1) 66%);
  height: 400px;
}

/* Circles animation */

.ct-circles-animation {
  position: fixed;
  top: 0;
  left: -150;
  width: 150%;
  height: 100%;
  overflow: hidden;
  z-index: -10;
}

.ct-circles-animation li {
  position: fixed;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #098efc;
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.ct-circles-animation li:nth-child(1) {
  left: 15%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.ct-circles-animation li:nth-child(2) {
  left: 5%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.ct-circles-animation li:nth-child(3) {
  left: 90%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.ct-circles-animation li:nth-child(4) {
  left: 84%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.ct-circles-animation li:nth-child(5) {
  left: 85%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.ct-circles-animation li:nth-child(6) {
  left: 0%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.ct-circles-animation li:nth-child(7) {
  left: 75%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.ct-circles-animation li:nth-child(8) {
  left: 90%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.ct-circles-animation li:nth-child(9) {
  left: 12%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.ct-circles-animation li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

.ct-circles-animation li:nth-child(11) {
  left: 50%;
  width: 250px;
  height: 250px;
  animation-delay: 0s;
  animation-duration: 11s;
}

.ct-circles-animation li:nth-child(12) {
  left: 30%;
  width: 100px;
  height: 100px;
  animation-delay: 0s;
  animation-duration: 11s;
}

.ct-circles-animation li:nth-child(13) {
  left: 40%;
  width: 200px;
  height: 200px;
  animation-delay: 0s;
  animation-duration: 9s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

/* Custom */

/* CSS */
.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #41aaff 0, #098efc 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 38px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  max-width: 200px;
  text-transform: uppercase;
  font-size: 12px;
}

.button-29.button-small {
  max-width: 150px;
  height: 30px;
}

.button-29:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-29 a {
  color: white;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Dashboard */

.ct-dashboard-list {
  margin-top: 80px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgba(250, 250, 250, 0.699);
  padding-bottom: 40px;
}

.ct-dashboard-list > .ct-charts-container {
  margin-bottom: 20px;
}

.ct-dashboard-list > .ct-charts-container .ct-treemap-chart {
  width: 100%;
  height: 200px;
}

.ct-investment-recomendation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;
}

.ct-investment-recomendation > h5 {
  font-size: 18px;
}

.ct-investment-recomendation > p {
  font-size: 14px;
}

.ct-road-map-page {
  margin-top: 100px;
}

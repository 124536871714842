.ct-investor-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 0;
  justify-content: flex-start;
}

.ct-investor-actions > div {
  width: 100%;
  max-width: 280px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  padding: 10px;
  position: relative;
  margin: 5px;
}

.ct-investor-actions > div > input,
.ct-investor-actions > div > button {
  width: 100%;
}

.ct-investor-actions > div .ct-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-investor-actions > div .ct-input-container > input {
  flex: 0 0 85%;
}

.ct-investor-actions > div .ct-input-container > button {
  background: transparent;
  color: black;
  text-decoration: underline;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-weight: lighter;
  padding-left: 10px;
}

.ct-investor-actions > div p {
  font-size: 11px;
  line-height: 10px;
  margin-top: 3px;
}

.ct-investor-actions > div ul {
  list-style: none;
  margin-top: 5px;
}

.ct-investor-actions > div ul > li {
  font-size: 9px;
  text-transform: uppercase;
}

.ct-investor-actions > div > .ct-deactivated-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(192, 16, 16, 0.644);
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  text-shadow: 5px 5px 10px #1b1b1b;
}

.ocean {
  height: 500px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
}

.ocean-inverted {
  height: 500px;
  width: 100%;
  position: absolute;
  top: 200px;
  left: 0;
  background: transparent;
  transform: rotateX(180deg);
}

.ct-ocean-continuation {
  background: #0189f8;
  background: linear-gradient(180deg, #0189f8 0%, #0189f8 50%, rgba(255, 255, 255, 1) 100%);
  height: 600px;
  position: absolute;
  top: 100px;
  width: 100%;
  left: 0;
  margin-top: -10px;
}

.wave {
  background: url(https://cdn.kcak11.com/codepen_assets/wave_animation/wave.svg) repeat-x;
  position: absolute;
  top: -100px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  -webkit-filter: grayscale(100%) invert(45%) sepia(84%) saturate(4656%) hue-rotate(189deg) brightness(103%) contrast(99%);
  filter: grayscale(100%) invert(45%) sepia(84%) saturate(4656%) hue-rotate(189deg) brightness(103%) contrast(99%);
}

.wave-inverted {
  transform: rotateX(180deg);
  bottom: 0;
  background: url(https://cdn.kcak11.com/codepen_assets/wave_animation/wave.svg) repeat-x;
  position: absolute;
  top: -1s0px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  -webkit-filter: grayscale(100%) invert(45%) sepia(84%) saturate(4656%) hue-rotate(189deg) brightness(103%) contrast(99%);
  filter: grayscale(100%) invert(45%) sepia(84%) saturate(4656%) hue-rotate(189deg) brightness(103%) contrast(99%);
}

.wave-inverted:nth-of-type(2) {
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

.wave:nth-of-type(2) {
  top: -50px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}
.endWave {
  display: none;
}

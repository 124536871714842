.ct-main-sliders-container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background-color: rgba(212, 212, 212, 0);
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 20%;
  object-fit: cover;
  height: 900px;
  padding-bottom: 200px;
  margin-bottom: 100px;
  margin-top: -300px;
  display: none;
}

.ct-main-sliders-container .ct-sliderOne {
  margin-top: 55px;
  padding: 30px;
  height: 500px;
  overflow: hidden;
}

.ct-main-sliders-container .ct-sliderOne .title {
  margin-top: 7%;
  margin-bottom: 5%;
  font-size: 50px;
  color: white;
  text-align: center;
  color: blanchedalmond;
  text-shadow: 1px 1px 2px rgb(19, 19, 19);
}

.ct-main-sliders-container .ct-sliderOne .ct-carousel-icon {
  background: rgba(2, 126, 251, 1);
  height: 30px;
  width: 30px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 90%;
  color: white;
  opacity: 0.7;
  z-index: 100;
}

.ct-main-sliders-container .ct-sliderOne .ct-carousel-icon.ct-next {
  right: 0px;
}

.ct-main-sliders-container .ct-sliderOne .ct-carousel-icon.ct-prev {
  left: 10px;
}

@media screen and (min-width: 900px) {
  .ct-main-sliders-container {
    display: block;
  }
}

/* Running campains */

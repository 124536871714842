.ct-stats-parent-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  position: relative;
  margin-top: -50px;
}

.ct-stats-parent-container > .ct-shadow-container {
  position: relative;
  width: 100%;
  background: rgba(19, 51, 61, 0.336);
  height: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
  background: rgb(11, 11, 11);
  background: transparent;
}

.ct-stats-parent-container > .ct-shadow-container > .ct-data-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 90%;
  z-index: 99;
  background: rgb(17, 88, 166);
  background: linear-gradient(41deg, rgba(17, 88, 166, 1) 0%, rgba(13, 10, 71, 1) 70%);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  flex-wrap: wrap;
  padding: 30px 0;
  position: relative;
  overflow: hidden;
}

.ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-stats-container {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
  min-width: 100px;
}

.ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-chart-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 120%;
  color: white;
}

.ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-stats-container span {
  color: white;
  font-size: 30px;
}

.ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-stats-container h1 {
  font-size: 12px;
  font-weight: bolder;
  text-align: center;
  font-family: "Oswald", sans-serif;
  color: white;
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.438);
}

.ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-stats-container h2 {
  font-size: 16px;
  font-weight: bolder;
  text-align: center;
  font-family: "Oswald", sans-serif;
  color: white;
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.438);
}

.ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-stats-container h1:nth-child(2) {
  margin-top: 10px;
}

.ct-stats-parent-container .ct-stats {
  border-radius: 50%;
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  animation: float 6s ease-in-out infinite;
}

@media screen and (min-width: 700px) {
  .ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-stats-container h1 {
    font-size: 18px;
  }

  .ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-stats-container {
    margin: 20px;
  }
}

.ct-stats-parent-container > .ct-shadow-container .ct-title {
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.651);
}

.ct-stats-parent-container > .ct-shadow-container .ct-title > h5 {
  font-size: 50px;
  margin-top: 30px;
}

.ct-stats-parent-container > .ct-shadow-container .ct-title > h4 {
  font-size: 60px;
  margin-top: -20px;
}

@media screen and (min-width: 900px) {
  .ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-stats-container h1 {
    font-size: 20px;
  }
}

.ct-info-banner-container {
  display: flex;
  z-index: 2;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: #098efc;
}

.ct-info-banner-container .ct-data-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 90px;
}

.ct-info-banner-container .ct-data-container > .ct-info-banner-text {
  font-family: "Roboto", sans-serif;
  text-align: left;
  color: white;
  width: 90%;
  max-width: 400px;
  margin-bottom: 40px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ct-info-banner-container .ct-data-container > .ct-info-banner-text h1 {
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.438);
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 20px;
}

.ct-info-banner-container .ct-data-container > .ct-info-banner-text p {
  font-weight: lighter;
  font-size: 16px;
}

.ct-info-banner-container .ct-data-container > .ct-info-banner-image {
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  object-fit: cover;
  align-items: center;
  width: 90%;
  max-width: 400px;
  animation: float 6s ease-in-out infinite;
}

@media screen and (min-width: 800px) {
  .ct-info-banner-container .ct-data-container > .ct-info-banner-text {
    max-width: 400px;
    padding: 20px;
    font-size: 18px;
  }
  .ct-info-banner-container .ct-data-container > .ct-info-banner-image {
    max-width: 400px;
  }

  .ct-info-banner-container .ct-data-container {
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 1000px) {
  .ct-info-banner-container .ct-data-container > .ct-info-banner-text {
    max-width: 500px;
    padding: 40px;
    font-size: 20px;
  }
  .ct-info-banner-container .ct-data-container > .ct-info-banner-image {
    max-width: 500px;
  }
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

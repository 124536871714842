.ct-info-banners-container {
  width: 100%;
  overflow: hidden;
}

.ct-security-banner-container {
  width: 90%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.ct-security-banner-container > div {
  overflow: hidden;
  max-width: 400px;
  width: 90%;
}

.ct-security-banner-container > div.ct-text-container {
  padding: 20px;
}

.ct-security-banner-container > img {
  width: 90%;
  height: 300px;
  max-width: 300px;
  object-fit: cover;
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  object-position: center;
}

.ct-security-banner-container > div.ct-text-container h4 {
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.137);
  font-size: 24px;
  line-height: 25px;
  margin-bottom: 20px;
  color: #098efc;
}

.ct-security-banner-container > div.ct-text-container p:nth-child(2) {
  font-weight: bold;
  margin-top: -15px;
  margin-bottom: 20px;
}

.ct-security-banner-container > div.ct-text-container p:nth-child(3) {
  font-weight: lighter;
  font-size: 16px;
}

@media screen and (min-width: 800px) {
  .ct-security-banner-container > img,
  .ct-security-banner-container > div {
    max-width: 500px;
  }

  .ct-security-banner-container > div {
    padding: 40px;
  }

  .ct-security-banner-container {
    padding-top: 150px;
  }
}

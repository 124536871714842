.ct-pool-list-item .ct-accordion-actions {
  display: flex;
  align-items: center;
}

.ct-pool-list-item .ct-accordion-actions img.ct-network-icon {
  height: 20px;
  width: 20px;
  margin-right: 2px;
}

.ct-small-testnet-pool {
  font-size: 11px;
  color: red;
  margin-left: 5px;
}

.ct-pool-list-item .ct-accordion-action {
  color: grey;
  font-size: 12px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-pool-list-item .Mui-expanded {
  transform: rotate(0deg) !important;
}

.ct-pool-list-item .ct-main-button {
  margin: 0;
  max-width: 150px;
  width: 90%;
  margin-top: 10px;
}

.ct-pool-list-item .ct-investment-link:hover {
  box-shadow: 0 5px 6px rgba(0, 171, 238, 0.384), 0 10px 10px rgba(0, 171, 238, 0.096);
}

.ct-pool-list-item .ct-title > h4 {
  font-weight: 400;
}

.ct-main-banner {
  display: flex;
  width: 100%;
  height: calc(100vh - 50px);
  background: white;
  overflow: hidden;
  position: relative;
  min-height: 900px;
}

.ct-main-banner > .ct-background {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.ct-main-banner > .ct-background > .ct-info-container {
  height: 100%;
  width: 100%;
  background: rgb(11, 11, 11);
  background: linear-gradient(180deg, #098ffcc2 30%, rgba(209, 209, 209, 0.534) 97%);
}

.ct-main-banner > .ct-background > .ct-info-container .ct-center-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ct-main-banner > .ct-background > .ct-info-container .ct-center-info h1 {
  font-size: 35px;
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.534);
}

.ct-main-banner > .ct-background > .ct-info-container .ct-center-info p {
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.534);
  font-size: 12px;
  text-transform: uppercase;
  margin-top: -7px;
  text-align: center;
}

.ct-main-banner > .ct-background > .ct-info-container .ct-center-info button {
  margin-top: 10px;
  width: 200px;
}

.ct-main-banner .ct-waves {
  position: absolute;
  bottom: 50px;
}

@media screen and (min-width: 450px) {
  .ct-main-banner > .ct-background > .ct-info-container .ct-center-info h1 {
    font-size: 60px;
  }

  .ct-main-banner > .ct-background > .ct-info-container .ct-center-info p {
    font-size: 16px;
  }
}

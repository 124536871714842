.ct-available-pools .ct-stats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 20px 0;
}

.ct-available-pools .ct-stats div {
  width: 90%;
  max-width: 150px;
  background: white;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}

.ct-available-pools .ct-stats div h4 {
  font-size: 14px;
}

.ct-available-pools .ct-stats div span {
  font-size: 12px;
}

.ct-pools-list > div {
  margin-bottom: 10px;
}

.ct-pools-list .ct-title {
  width: 25%;
  min-width: 200px;
}

.ct-pools-list .ct-title h4 {
  font-size: 16px;
}

.ct-pools-list .ct-title small {
  font-size: 12px;
}

.ct-pools-list .ct-data {
  display: flex;
  align-items: center;
  height: 30px;
  width: 55%;
  min-width: 0px;
}

.ct-pools-list .ct-data span {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: rgb(24, 24, 24);
  font-weight: bold;
  padding-top: 5px;
  align-items: center;
  width: 25%;
}

.ct-pools-list .ct-data span:nth-child(1) {
  display: none;
}

.ct-pools-list .ct-data span:nth-child(2) {
  display: none;
}

.ct-pools-list .ct-data span:nth-child(3) {
  display: none;
}

.ct-pools-list .ct-data span:nth-child(4) {
  display: none;
}

@media screen and (min-width: 550px) {
  .ct-pools-list .ct-data span:nth-child(1) {
    display: flex;
  }
}

@media screen and (min-width: 600px) {
  .ct-pools-list .ct-data span:nth-child(2) {
    display: flex;
  }
}

@media screen and (min-width: 650px) {
  .ct-pools-list .ct-data span:nth-child(3) {
    display: flex;
  }
}

@media screen and (min-width: 700px) {
  .ct-pools-list .ct-data span:nth-child(4) {
    display: flex;
  }
}

.ct-pools-list .MuiAccordionSummary-content {
  align-items: center;
}

.ct-pools-list .ct-data span strong {
  font-size: 10px;
  text-transform: uppercase;
  color: rgb(83, 83, 83);
}

.ct-pools-list .ct-pool-data > .ct-admin-data {
  padding: 0 20px 20px 20px;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-pool-info {
  display: flex;
  flex-direction: column;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-pool-info h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-pool-info .ct-pool-desc {
  font-size: 12px;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-pool-info p {
  font-size: 12px;
  margin-bottom: 6px;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-pool-info .ct-data-container {
  font-size: 12px;
  margin-top: 10px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-pool-info .ct-data-container > .ct-data-flush {
  min-width: 300px;
  display: flex;
  flex-direction: column;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-pool-info .ct-data-container > .ct-links {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-charts > div {
  background: rgba(255, 255, 255, 0.432);
  border-radius: 10px;
  padding: 15px;
  margin-top: 25px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-charts > div > h4 {
  font-size: 16px;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-charts .ct-rewards {
  width: 100%;
  height: 200px;
}

@media screen and (min-width: 499px) {
  .ct-pools-list .ct-pool-data > .ct-admin-data > .ct-pool-info .ct-data-container > .ct-links {
    text-align: right;
  }
}

.ct-pools-list .ct-pool-data > .ct-investor-container {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 20px;
  margin-bottom: 15px;
}

.ct-pools-list .ct-pool-data > .ct-investor-container h4 {
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor-data {
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor-data a:nth-child(5) {
  margin-top: 10px;
}

.ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor > .ct-investor-actions {
  min-width: 250px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor > .ct-investor-actions div {
  width: 100%;
  max-width: 200px;
  font-size: 12px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  padding: 10px 20px;
}

.ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor > .ct-investor-actions div h4 {
  width: 100%;
  text-align: center;
}

.ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor > .ct-investor-actions div input {
  width: 100%;
  height: 30px;
}

.ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor > .ct-investor-actions div button {
  font-size: 10px;
}

@media screen and (min-width: 499px) {
  .ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor > .ct-investor-actions {
    margin-top: 0;
  }

  .ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor > .ct-investor-actions div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

@media screen and (min-width: 900px) {
  .ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor > .ct-investor-actions div {
    max-width: 400px;
  }
}

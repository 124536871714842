@import url(https://fonts.googleapis.com/css2?family=Bangers&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Material+Icons&family=Anton&family=Permanent+Marker&family=Roboto+Slab:wght@300;400;500;600;700&family=VT323&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,600;0,900;0,1000;1,200;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Oswald:wght@200;300;400;500;600;700&display=swap);
.ct-top-nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  z-index: 9999;
  background: transparent;
}

.ct-top-nav.ct-focused-navigation {
  background: #098efc !important;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%) !important;
}

.ct-top-nav > .ct-max-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.ct-top-nav > .ct-max-container > .ct-title {
  padding: 0 20px 0 20px;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.ct-top-nav > .ct-max-container > .ct-title > img {
  width: 40px;
  margin-right: 6px;
}

.ct-top-nav > .ct-max-container > .ct-title > h1 {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: white;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ct-top-nav > .ct-max-container > .ct-links {
  display: flex;
  padding-right: 20px;
  color: black;
  align-items: center;
}

.ct-top-nav > .ct-max-container > .ct-links a {
  color: white;
  text-decoration: none;
  height: 40px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: lighter;
  font-size: 12px;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}

.ct-chain-selector-wrapper {
  outline: none;
}

#select-blockchain {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
}

#select-blockchain > span {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
}

.ct-chain-selector-wrapper select,
.ct-chain-selector-wrapper select:active,
.ct-chain-selector-wrapper select:focus,
.ct-chain-selector-wrapper select:focus-visible,
.ct-chain-selector-wrapper div:focus-visible,
.ct-chain-selector-wrapper fieldset,
.ct-chain-selector-wrapper select:hover {
  outline: none !important;
  border: 0;
  border-radius: 0;
  border-bottom: 0px solid rgba(2, 126, 251, 1) !important;
}

.ct-chain .ct-chain-icon,
.ct-chain-selector .ct-chain-icon {
  height: 18px;
  width: 18px;
  margin-right: 7px;
}

.ct-top-nav > .ct-max-container > .ct-links > .ct-data {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ct-top-nav > .ct-max-container > .ct-links > div.ct-address {
  font-weight: lighter;
  width: 100px;
  text-align: center;
  margin-left: 20px;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-balance {
  font-size: 9px;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: right;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-balance > a {
  font-size: 11px;
  margin-right: 7px;
  cursor: pointer;
  color: black;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-address {
  text-overflow: ellipsis;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  direction: rtl;
  font-size: 12px;
  font-weight: bold;
  text-align: right;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-actions {
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-actions > span,
.ct-top-nav > .ct-max-container > .ct-links .ct-actions > a {
  cursor: pointer;
  margin-left: 5px;
  font-size: 16px;
}

@media screen and (min-width: 500px) {
  .ct-top-nav > .ct-max-container > .ct-links .ct-balance {
    font-size: 12px;
  }

  .ct-top-nav > .ct-max-container > .ct-links a {
    font-size: 12px;
  }

  .ct-top-nav > .ct-max-container > .ct-links .ct-balance > a {
    font-size: 12px;
  }

  .ct-top-nav > .ct-max-container > .ct-links .ct-address {
    font-size: 12px;
  }
}

@media screen and (min-width: 1600px) {
  .ct-top-nav > .ct-max-container > .ct-links .ct-actions .ct-left-toggler {
    display: none;
  }
}

.ct-blockchain-error {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.671);
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ct-blockchain-error > div {
  background: white;
  padding: 20px;
  text-align: center;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  width: 90%;
  max-width: 400px;
  border-radius: 10px;
}

.ct-blockchain-error > div > h4 {
  font-size: 25px;
}

.ct-blockchain-error > div .ct-suggested-networks,
.ct-blockchain-error > div .ct-suggested-providers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.ct-blockchain-error > div .ct-suggested-networks > div.ct-chain-container,
.ct-blockchain-error > div .ct-suggested-providers > div.ct-provider-container {
  width: 100px;
  height: 100px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  cursor: pointer;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ct-blockchain-error > div .ct-suggested-networks > div.ct-chain-container > img,
.ct-blockchain-error > div .ct-suggested-providers > div.ct-provider-container > img {
  width: 60%;
  margin-bottom: 10px;
}

.ct-blockchain-error > div .ct-suggested-networks > div.ct-chain-container:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-footer {
  width: 100%;
  margin-top: 300px;
  padding-top: 100px;
  padding-bottom: 100px;
  box-shadow: 0px -5px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  background: #098efc;
}

.ct-footer > .ct-big-info-container {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ct-footer > .ct-big-info-container > .ct-big-info {
  margin: 15px;
  display: flex;
}

.ct-footer > .ct-big-info-container > .ct-big-info > h4 {
  width: 100%;
  margin-bottom: 15px;
  font-size: 14px;
  color: white;
}

.ct-footer > .ct-big-info-container > .ct-big-info > a > span {
  margin-left: 7px;
  font-size: 25px;
  color: white;
}

.ct-footer > .ct-big-info-container > .ct-big-info > a {
  color: black;
  text-decoration: none;
  font-size: 25px;
  color: white;
  margin: 5px;
}

.ct-footer > .ct-big-info-container > .ct-links {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.ct-footer > .ct-big-info-container > .ct-links > a,
.ct-footer > .ct-big-info-container > .ct-links > span {
  color: white;
  font-size: 12px;
}

.ct-pool-list-item .ct-accordion-actions {
  display: flex;
  align-items: center;
}

.ct-pool-list-item .ct-accordion-actions img.ct-network-icon {
  height: 20px;
  width: 20px;
  margin-right: 2px;
}

.ct-small-testnet-pool {
  font-size: 11px;
  color: red;
  margin-left: 5px;
}

.ct-pool-list-item .ct-accordion-action {
  color: grey;
  font-size: 12px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-pool-list-item .Mui-expanded {
  -webkit-transform: rotate(0deg) !important;
          transform: rotate(0deg) !important;
}

.ct-pool-list-item .ct-main-button {
  margin: 0;
  max-width: 150px;
  width: 90%;
  margin-top: 10px;
}

.ct-pool-list-item .ct-investment-link:hover {
  box-shadow: 0 5px 6px rgba(0, 171, 238, 0.384), 0 10px 10px rgba(0, 171, 238, 0.096);
}

.ct-pool-list-item .ct-title > h4 {
  font-weight: 400;
}

.ct-available-pools .ct-stats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 20px 0;
}

.ct-available-pools .ct-stats div {
  width: 90%;
  max-width: 150px;
  background: white;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}

.ct-available-pools .ct-stats div h4 {
  font-size: 14px;
}

.ct-available-pools .ct-stats div span {
  font-size: 12px;
}

.ct-pools-list > div {
  margin-bottom: 10px;
}

.ct-pools-list .ct-title {
  width: 25%;
  min-width: 200px;
}

.ct-pools-list .ct-title h4 {
  font-size: 16px;
}

.ct-pools-list .ct-title small {
  font-size: 12px;
}

.ct-pools-list .ct-data {
  display: flex;
  align-items: center;
  height: 30px;
  width: 55%;
  min-width: 0px;
}

.ct-pools-list .ct-data span {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: rgb(24, 24, 24);
  font-weight: bold;
  padding-top: 5px;
  align-items: center;
  width: 25%;
}

.ct-pools-list .ct-data span:nth-child(1) {
  display: none;
}

.ct-pools-list .ct-data span:nth-child(2) {
  display: none;
}

.ct-pools-list .ct-data span:nth-child(3) {
  display: none;
}

.ct-pools-list .ct-data span:nth-child(4) {
  display: none;
}

@media screen and (min-width: 550px) {
  .ct-pools-list .ct-data span:nth-child(1) {
    display: flex;
  }
}

@media screen and (min-width: 600px) {
  .ct-pools-list .ct-data span:nth-child(2) {
    display: flex;
  }
}

@media screen and (min-width: 650px) {
  .ct-pools-list .ct-data span:nth-child(3) {
    display: flex;
  }
}

@media screen and (min-width: 700px) {
  .ct-pools-list .ct-data span:nth-child(4) {
    display: flex;
  }
}

.ct-pools-list .MuiAccordionSummary-content {
  align-items: center;
}

.ct-pools-list .ct-data span strong {
  font-size: 10px;
  text-transform: uppercase;
  color: rgb(83, 83, 83);
}

.ct-pools-list .ct-pool-data > .ct-admin-data {
  padding: 0 20px 20px 20px;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-pool-info {
  display: flex;
  flex-direction: column;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-pool-info h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-pool-info .ct-pool-desc {
  font-size: 12px;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-pool-info p {
  font-size: 12px;
  margin-bottom: 6px;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-pool-info .ct-data-container {
  font-size: 12px;
  margin-top: 10px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-pool-info .ct-data-container > .ct-data-flush {
  min-width: 300px;
  display: flex;
  flex-direction: column;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-pool-info .ct-data-container > .ct-links {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-charts > div {
  background: rgba(255, 255, 255, 0.432);
  border-radius: 10px;
  padding: 15px;
  margin-top: 25px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-charts > div > h4 {
  font-size: 16px;
}

.ct-pools-list .ct-pool-data > .ct-admin-data > .ct-charts .ct-rewards {
  width: 100%;
  height: 200px;
}

@media screen and (min-width: 499px) {
  .ct-pools-list .ct-pool-data > .ct-admin-data > .ct-pool-info .ct-data-container > .ct-links {
    text-align: right;
  }
}

.ct-pools-list .ct-pool-data > .ct-investor-container {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 20px;
  margin-bottom: 15px;
}

.ct-pools-list .ct-pool-data > .ct-investor-container h4 {
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor-data {
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor-data a:nth-child(5) {
  margin-top: 10px;
}

.ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor > .ct-investor-actions {
  min-width: 250px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor > .ct-investor-actions div {
  width: 100%;
  max-width: 200px;
  font-size: 12px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  padding: 10px 20px;
}

.ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor > .ct-investor-actions div h4 {
  width: 100%;
  text-align: center;
}

.ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor > .ct-investor-actions div input {
  width: 100%;
  height: 30px;
}

.ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor > .ct-investor-actions div button {
  font-size: 10px;
}

@media screen and (min-width: 499px) {
  .ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor > .ct-investor-actions {
    margin-top: 0;
  }

  .ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor > .ct-investor-actions div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

@media screen and (min-width: 900px) {
  .ct-pools-list .ct-pool-data > .ct-investor-container .ct-investor > .ct-investor-actions div {
    max-width: 400px;
  }
}

.ocean {
  height: 500px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
}

.ocean-inverted {
  height: 500px;
  width: 100%;
  position: absolute;
  top: 200px;
  left: 0;
  background: transparent;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
}

.ct-ocean-continuation {
  background: #0189f8;
  background: linear-gradient(180deg, #0189f8 0%, #0189f8 50%, rgba(255, 255, 255, 1) 100%);
  height: 600px;
  position: absolute;
  top: 100px;
  width: 100%;
  left: 0;
  margin-top: -10px;
}

.wave {
  background: url(https://cdn.kcak11.com/codepen_assets/wave_animation/wave.svg) repeat-x;
  position: absolute;
  top: -100px;
  width: 6400px;
  height: 198px;
  -webkit-animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
          animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-filter: grayscale(100%) invert(45%) sepia(84%) saturate(4656%) hue-rotate(189deg) brightness(103%) contrast(99%);
  filter: grayscale(100%) invert(45%) sepia(84%) saturate(4656%) hue-rotate(189deg) brightness(103%) contrast(99%);
}

.wave-inverted {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  bottom: 0;
  background: url(https://cdn.kcak11.com/codepen_assets/wave_animation/wave.svg) repeat-x;
  position: absolute;
  top: -1s0px;
  width: 6400px;
  height: 198px;
  -webkit-animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
          animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-filter: grayscale(100%) invert(45%) sepia(84%) saturate(4656%) hue-rotate(189deg) brightness(103%) contrast(99%);
  filter: grayscale(100%) invert(45%) sepia(84%) saturate(4656%) hue-rotate(189deg) brightness(103%) contrast(99%);
}

.wave-inverted:nth-of-type(2) {
  -webkit-animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
          animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

.wave:nth-of-type(2) {
  top: -50px;
  -webkit-animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
          animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@-webkit-keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@-webkit-keyframes swell {
  0%,
  100% {
    -webkit-transform: translate3d(0, -25px, 0);
            transform: translate3d(0, -25px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
}
@keyframes swell {
  0%,
  100% {
    -webkit-transform: translate3d(0, -25px, 0);
            transform: translate3d(0, -25px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
}
.endWave {
  display: none;
}

.ct-main-banner {
  display: flex;
  width: 100%;
  height: calc(100vh - 50px);
  background: white;
  overflow: hidden;
  position: relative;
  min-height: 900px;
}

.ct-main-banner > .ct-background {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.ct-main-banner > .ct-background > .ct-info-container {
  height: 100%;
  width: 100%;
  background: rgb(11, 11, 11);
  background: linear-gradient(180deg, #098ffcc2 30%, rgba(209, 209, 209, 0.534) 97%);
}

.ct-main-banner > .ct-background > .ct-info-container .ct-center-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ct-main-banner > .ct-background > .ct-info-container .ct-center-info h1 {
  font-size: 35px;
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.534);
}

.ct-main-banner > .ct-background > .ct-info-container .ct-center-info p {
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.534);
  font-size: 12px;
  text-transform: uppercase;
  margin-top: -7px;
  text-align: center;
}

.ct-main-banner > .ct-background > .ct-info-container .ct-center-info button {
  margin-top: 10px;
  width: 200px;
}

.ct-main-banner .ct-waves {
  position: absolute;
  bottom: 50px;
}

@media screen and (min-width: 450px) {
  .ct-main-banner > .ct-background > .ct-info-container .ct-center-info h1 {
    font-size: 60px;
  }

  .ct-main-banner > .ct-background > .ct-info-container .ct-center-info p {
    font-size: 16px;
  }
}

.ct-slider-item {
  height: 350px;
  width: 100vw;
  max-width: 400px;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin: 10px;
  margin-right: 30px;
  margin-bottom: 50px;
}

.ct-slider-item:hover {
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}

.ct-slider-item .imageContainer {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ct-slider-item .infoContainer {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.ct-slider-item .infoContainer .overlay {
  background-color: rgba(9, 144, 240, 0.89);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ct-slider-item .infoContainer p {
  color: white;
  z-index: 10;
  padding: 10px;
  font-size: 12px;
}

.ct-slider-item .titleItem {
  color: white;
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.438);
  position: absolute;
  top: -30px;
  left: 10px;
  font-weight: bolder;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}

.ct-slider-item .infoContainer .progressBarContainer {
  text-align: center;
  height: 15px;
  width: 100%;
  background-color: rgb(250, 253, 249);
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
}

.ct-slider-item .progressBarContainer .progressBar {
  height: 100%;
  background-color: rgb(27, 219, 210);
}

.ct-slider-item .bottomContainer {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-slider-item .button {
  margin-top: auto;
  margin-bottom: auto;
}

.ct-slider-item .goalContainer {
  width: 50%;
  height: 100%;
}

.ct-slider-item .goal1 {
  padding-top: 3px;
  width: 100%;
  height: 40%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  text-shadow: 1px 1px 2px rgb(19, 19, 19);
}

.ct-slider-item .goal2 {
  margin-top: -5px;
  width: 100%;
  height: 60%;
  text-align: center;
  color: white;
  font-size: 26px;
  text-shadow: 1px 1px 2px rgb(19, 19, 19);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-slider-item .icon {
  margin-bottom: 1.7%;
  width: 20%;
  padding-right: 8px;
  font-size: 18px;
  color: white;
  text-shadow: 1px 1px 2px rgb(19, 19, 19);
}

.ct-slider-item .icon2 {
  font-size: 15px;
  color: white;
}

.ct-slider-item.ct-active-slider {
  border: 4px solid white;
  box-shadow: 0 19px 38px rgba(255, 255, 255, 0.3), 0 15px 12px rgba(255, 255, 255, 0.22);
}

.ct-main-sliders-container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background-color: rgba(212, 212, 212, 0);
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 20%;
  object-fit: cover;
  height: 900px;
  padding-bottom: 200px;
  margin-bottom: 100px;
  margin-top: -300px;
  display: none;
}

.ct-main-sliders-container .ct-sliderOne {
  margin-top: 55px;
  padding: 30px;
  height: 500px;
  overflow: hidden;
}

.ct-main-sliders-container .ct-sliderOne .title {
  margin-top: 7%;
  margin-bottom: 5%;
  font-size: 50px;
  color: white;
  text-align: center;
  color: blanchedalmond;
  text-shadow: 1px 1px 2px rgb(19, 19, 19);
}

.ct-main-sliders-container .ct-sliderOne .ct-carousel-icon {
  background: rgba(2, 126, 251, 1);
  height: 30px;
  width: 30px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 90%;
  color: white;
  opacity: 0.7;
  z-index: 100;
}

.ct-main-sliders-container .ct-sliderOne .ct-carousel-icon.ct-next {
  right: 0px;
}

.ct-main-sliders-container .ct-sliderOne .ct-carousel-icon.ct-prev {
  left: 10px;
}

@media screen and (min-width: 900px) {
  .ct-main-sliders-container {
    display: block;
  }
}

/* Running campains */

.ct-main-sliders-container .ct-project-description {
  background: white;
  color: black;
  display: flex;
  overflow: hidden;
  margin-bottom: 100px;
  flex-direction: column;
  margin-top: -30px;
  width: 90%;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  transition: all 0.2s ease;
}

.ct-main-sliders-container .ct-project-description:hover {
  box-shadow: 0 7px 14px rgb(0 0 0 / 25%), 0 5px 5px rgb(0 0 0 / 22%);
}

.ct-main-sliders-container .ct-project-description .ct-image-container img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
  min-height: 200px;
  max-height: 400px;
}

.ct-main-sliders-container .ct-project-description .ct-profit-container {
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 200px;
  max-height: 400px;
  padding: 20px;
  flex-direction: column;
  position: relative;
}

.ct-main-sliders-container .ct-project-description .ct-profit-container h4 {
  font-size: 35px;
  z-index: 10;
  color: rgba(9, 142, 240, 1);
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.438);
}

.ct-main-sliders-container .ct-project-description .ct-profit-container > small {
  font-weight: bold;
  margin-bottom: 10px;
  z-index: 10;
}

.ct-main-sliders-container .ct-project-description .ct-profit-container p {
  z-index: 10;
}

.ct-main-sliders-container .ct-project-description .ct-profit-container button {
  z-index: 10;
}

.ct-main-sliders-container .ct-project-description .ct-profit-container .ct-chart-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 120%;
  height: 100%;
}

@media screen and (min-width: 900px) {
  .ct-main-sliders-container .ct-project-description {
    flex-direction: row;
    height: 400px;
  }

  .ct-main-sliders-container .ct-project-description > div:nth-child(1) {
    flex: 0 0 40%;
    max-width: 40%;
    overflow: hidden;
  }

  .ct-main-sliders-container .ct-project-description > div:nth-child(2) {
    flex: 0 0 60%;
    max-width: 60%;
    overflow: hidden;
  }

  .ct-main-sliders-container .ct-project-description .ct-profit-container {
    max-height: auto;
    width: 100%;
    min-height: auto;
  }
}

.ct-stats-parent-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  position: relative;
  margin-top: -50px;
}

.ct-stats-parent-container > .ct-shadow-container {
  position: relative;
  width: 100%;
  background: rgba(19, 51, 61, 0.336);
  height: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
  background: rgb(11, 11, 11);
  background: transparent;
}

.ct-stats-parent-container > .ct-shadow-container > .ct-data-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 90%;
  z-index: 99;
  background: rgb(17, 88, 166);
  background: linear-gradient(41deg, rgba(17, 88, 166, 1) 0%, rgba(13, 10, 71, 1) 70%);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  flex-wrap: wrap;
  padding: 30px 0;
  position: relative;
  overflow: hidden;
}

.ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-stats-container {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
  min-width: 100px;
}

.ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-chart-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 120%;
  color: white;
}

.ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-stats-container span {
  color: white;
  font-size: 30px;
}

.ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-stats-container h1 {
  font-size: 12px;
  font-weight: bolder;
  text-align: center;
  font-family: "Oswald", sans-serif;
  color: white;
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.438);
}

.ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-stats-container h2 {
  font-size: 16px;
  font-weight: bolder;
  text-align: center;
  font-family: "Oswald", sans-serif;
  color: white;
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.438);
}

.ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-stats-container h1:nth-child(2) {
  margin-top: 10px;
}

.ct-stats-parent-container .ct-stats {
  border-radius: 50%;
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  -webkit-animation: float 6s ease-in-out infinite;
          animation: float 6s ease-in-out infinite;
}

@media screen and (min-width: 700px) {
  .ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-stats-container h1 {
    font-size: 18px;
  }

  .ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-stats-container {
    margin: 20px;
  }
}

.ct-stats-parent-container > .ct-shadow-container .ct-title {
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.651);
}

.ct-stats-parent-container > .ct-shadow-container .ct-title > h5 {
  font-size: 50px;
  margin-top: 30px;
}

.ct-stats-parent-container > .ct-shadow-container .ct-title > h4 {
  font-size: 60px;
  margin-top: -20px;
}

@media screen and (min-width: 900px) {
  .ct-stats-parent-container > .ct-shadow-container > .ct-data-container .ct-stats-container h1 {
    font-size: 20px;
  }
}

.ocean3-inverted {
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: -200px;
  left: 0;
  background: transparent;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  overflow: hidden;
}

.wave3-inverted {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  background: #0189f8;
  z-index: 99;
  top: 20px;
  background: url(https://cdn.kcak11.com/codepen_assets/wave_animation/wave.svg) repeat-x;
  position: absolute;
  width: 6400px;
  height: 198px;
  -webkit-animation: wave 50s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
          animation: wave 50s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-filter: grayscale(100%) invert(45%) sepia(84%) saturate(4656%) hue-rotate(189deg) brightness(103%) contrast(99%);
  filter: grayscale(100%) invert(45%) sepia(84%) saturate(4656%) hue-rotate(189deg) brightness(103%) contrast(99%);
}

.wave3-inverted:nth-of-type(2) {
  -webkit-animation: wave 50s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
          animation: wave 50s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@-webkit-keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@-webkit-keyframes swell {
  0%,
  100% {
    -webkit-transform: translate3d(0, -25px, 0);
            transform: translate3d(0, -25px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
}
@keyframes swell {
  0%,
  100% {
    -webkit-transform: translate3d(0, -25px, 0);
            transform: translate3d(0, -25px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
}

.endWave {
  display: none;
}

.ct-info-banner-container {
  display: flex;
  z-index: 2;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: #098efc;
}

.ct-info-banner-container .ct-data-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 90px;
}

.ct-info-banner-container .ct-data-container > .ct-info-banner-text {
  font-family: "Roboto", sans-serif;
  text-align: left;
  color: white;
  width: 90%;
  max-width: 400px;
  margin-bottom: 40px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ct-info-banner-container .ct-data-container > .ct-info-banner-text h1 {
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.438);
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 20px;
}

.ct-info-banner-container .ct-data-container > .ct-info-banner-text p {
  font-weight: lighter;
  font-size: 16px;
}

.ct-info-banner-container .ct-data-container > .ct-info-banner-image {
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  object-fit: cover;
  align-items: center;
  width: 90%;
  max-width: 400px;
  -webkit-animation: float 6s ease-in-out infinite;
          animation: float 6s ease-in-out infinite;
}

@media screen and (min-width: 800px) {
  .ct-info-banner-container .ct-data-container > .ct-info-banner-text {
    max-width: 400px;
    padding: 20px;
    font-size: 18px;
  }
  .ct-info-banner-container .ct-data-container > .ct-info-banner-image {
    max-width: 400px;
  }

  .ct-info-banner-container .ct-data-container {
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 1000px) {
  .ct-info-banner-container .ct-data-container > .ct-info-banner-text {
    max-width: 500px;
    padding: 40px;
    font-size: 20px;
  }
  .ct-info-banner-container .ct-data-container > .ct-info-banner-image {
    max-width: 500px;
  }
}

@-webkit-keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

.ct-info-banners-container {
  width: 100%;
  overflow: hidden;
}

.ct-security-banner-container {
  width: 90%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.ct-security-banner-container > div {
  overflow: hidden;
  max-width: 400px;
  width: 90%;
}

.ct-security-banner-container > div.ct-text-container {
  padding: 20px;
}

.ct-security-banner-container > img {
  width: 90%;
  height: 300px;
  max-width: 300px;
  object-fit: cover;
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  object-position: center;
}

.ct-security-banner-container > div.ct-text-container h4 {
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.137);
  font-size: 24px;
  line-height: 25px;
  margin-bottom: 20px;
  color: #098efc;
}

.ct-security-banner-container > div.ct-text-container p:nth-child(2) {
  font-weight: bold;
  margin-top: -15px;
  margin-bottom: 20px;
}

.ct-security-banner-container > div.ct-text-container p:nth-child(3) {
  font-weight: lighter;
  font-size: 16px;
}

@media screen and (min-width: 800px) {
  .ct-security-banner-container > img,
  .ct-security-banner-container > div {
    max-width: 500px;
  }

  .ct-security-banner-container > div {
    padding: 40px;
  }

  .ct-security-banner-container {
    padding-top: 150px;
  }
}

.ct-launched-projects {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 100vh;
  min-height: 1000px;
}

.ct-launched-projects iframe {
  width: calc(100% + 30%);
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  left: -30%;
  z-index: -10;
  background: rgba(9, 142, 240, 1);
}

.ct-launched-projects .ct-launched-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ct-launched-projects .ct-launched-container > .ct-last-projects-list > h6 {
  position: absolute;
  z-index: 10;
  color: white;
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.438);
  bottom: -80px;
  font-size: 12px;
  left: 0;
}

.ct-launched-projects .ct-launched-container > h4 {
  font-size: 30px;
  color: white;
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.438);
}

.ct-launched-projects .ct-launched-container > small,
.ct-launched-projects .ct-launched-container > a {
  color: black;
  font-weight: bold;
}

.ct-launched-projects .ct-launched-container > a {
  font-size: 12px;
}

.ct-launched-projects .ct-launched-container > .ct-last-projects-list {
  margin-top: 30px;
  background: rgba(9, 143, 252, 0.678);
  padding: 40px;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  position: relative;
}

.ct-launched-projects .ct-launched-container > .ct-last-projects-list > div {
  display: flex;
  justify-content: space-between;
}

.ct-launched-projects .ct-launched-container > .ct-last-projects-list > div > a {
  color: white;
  font-size: 20px;
}

.ct-launched-projects .ct-launched-container > .ct-last-projects-list > div > div {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.ct-launched-projects .ct-launched-container > .ct-last-projects-list > div h4 {
  color: white;
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.534);
}

.ct-launched-projects .ct-launched-container > .ct-last-projects-list > div > div > span,
.ct-launched-projects .ct-launched-container > .ct-last-projects-list > div > div > p {
  font-size: 11px;
  font-weight: bold;
  color: white;
  font-weight: bold;
}

.timeline {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  position: relative;
}

.timeline__title {
  font-size: 28px;
  color: rgb(9, 142, 252);
  text-align: center;
}
.timeline__event {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  margin: 20px 0;
  border-radius: 6px;
  align-self: center;
  width: 70vw;
  max-width: 1200px;
}
.timeline__event:nth-child(2n + 1) {
  flex-direction: row-reverse;
}
.timeline__event:nth-child(2n + 1) .timeline__event__date {
  border-radius: 0 6px 6px 0;
}
.timeline__event:nth-child(2n + 1) .timeline__event__content {
  border-radius: 6px 0 0 6px;
}

.timeline__event .timeline__event__icon > img {
  width: 70px;
  height: 70px;
}

.timeline__event:nth-child(2n + 1) .timeline__event__icon:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #f6a4ec;
  position: absolute;
  top: 0%;
  left: 50%;
  right: auto;
  z-index: -1;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-animation: fillTop 2s forwards 4s ease-in-out;
  animation: fillTop 2s forwards 4s ease-in-out;
}
.timeline__event:nth-child(2n + 1) .timeline__event__icon:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #f6a4ec;
  position: absolute;
  right: 0;
  z-index: -1;
  top: 50%;
  left: auto;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-animation: fillLeft 2s forwards 4s ease-in-out;
  animation: fillLeft 2s forwards 4s ease-in-out;
}
.timeline__event__title {
  font-size: 1.2rem;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 600;
  color: #9251ac;
  letter-spacing: 1.5px;
}
.timeline__event__content {
  padding: 20px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  background: #fff;
  width: calc(60vw - 84px);
  border-radius: 0 6px 6px 0;
}
.timeline__event__date {
  color: #f6a4ec;
  font-size: 1.5rem;
  font-weight: 600;
  background: #9251ac;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 20px;
  border-radius: 6px 0 0 6px;
}
.timeline__event__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9251ac;
  padding: 20px;
  align-self: center;
  margin: 0 20px;
  background: #f6a4ec;
  border-radius: 100%;
  width: 40px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  padding: 40px;
  height: 40px;
  position: relative;
}
.timeline__event__icon i {
  font-size: 32px;
}
.timeline__event__icon:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #f6a4ec;
  position: absolute;
  top: 0%;
  z-index: -1;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-animation: fillTop 2s forwards 4s ease-in-out;
  animation: fillTop 2s forwards 4s ease-in-out;
}
.timeline__event__icon:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #f6a4ec;
  position: absolute;
  left: 0%;
  z-index: -1;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-animation: fillLeftOdd 2s forwards 4s ease-in-out;
  animation: fillLeftOdd 2s forwards 4s ease-in-out;
}
.timeline__event__description {
  flex-basis: 100%;
}
.timeline__event--type2:after {
  background: #555ac0;
}
.timeline__event--type2 .timeline__event__date {
  color: #87bbfe;
  background: #555ac0;
}
.timeline__event--type2:nth-child(2n + 1) .timeline__event__icon:before,
.timeline__event--type2:nth-child(2n + 1) .timeline__event__icon:after {
  background: #87bbfe;
}
.timeline__event--type2 .timeline__event__icon {
  background: #87bbfe;
  color: #555ac0;
}
.timeline__event--type2 .timeline__event__icon:before,
.timeline__event--type2 .timeline__event__icon:after {
  background: #87bbfe;
}
.timeline__event--type2 .timeline__event__title {
  color: #555ac0;
}
.timeline__event--type3:after {
  background: #24b47e;
}
.timeline__event--type3 .timeline__event__date {
  color: #aff1b6;
  background-color: #24b47e;
}
.timeline__event--type3:nth-child(2n + 1) .timeline__event__icon:before,
.timeline__event--type3:nth-child(2n + 1) .timeline__event__icon:after {
  background: #aff1b6;
}
.timeline__event--type3 .timeline__event__icon {
  background: #aff1b6;
  color: #24b47e;
}
.timeline__event--type3 .timeline__event__icon:before,
.timeline__event--type3 .timeline__event__icon:after {
  background: #aff1b6;
}
.timeline__event--type3 .timeline__event__title {
  color: #24b47e;
}
.timeline__event:last-child .timeline__event__icon:before {
  content: none;
}

@media (max-width: 786px) {
  .timeline__event {
    flex-direction: column;
    align-self: center;
  }
  .timeline__event__content {
    width: 100%;
  }
  .timeline__event__icon {
    border-radius: 6px 6px 0 0;
    width: 100%;
    margin: 0;
    box-shadow: none;
  }
  .timeline__event__icon:before,
  .timeline__event__icon:after {
    display: none;
  }
  .timeline__event__date {
    border-radius: 0;
    padding: 20px;
  }
  .timeline__event:nth-child(2n + 1) {
    flex-direction: column;
    align-self: center;
  }
  .timeline__event:nth-child(2n + 1) .timeline__event__date {
    border-radius: 0;
    padding: 20px;
  }
  .timeline__event:nth-child(2n + 1) .timeline__event__icon {
    border-radius: 6px 6px 0 0;
    margin: 0;
  }
}
@-webkit-keyframes fillLeft {
  100% {
    right: 100%;
  }
}
@keyframes fillLeft {
  100% {
    right: 100%;
  }
}
@-webkit-keyframes fillTop {
  100% {
    top: 100%;
  }
}
@keyframes fillTop {
  100% {
    top: 100%;
  }
}
@-webkit-keyframes fillLeftOdd {
  100% {
    left: 100%;
  }
}
@keyframes fillLeftOdd {
  100% {
    left: 100%;
  }
}

.ct-investor-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 0;
  justify-content: flex-start;
}

.ct-investor-actions > div {
  width: 100%;
  max-width: 280px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  padding: 10px;
  position: relative;
  margin: 5px;
}

.ct-investor-actions > div > input,
.ct-investor-actions > div > button {
  width: 100%;
}

.ct-investor-actions > div .ct-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-investor-actions > div .ct-input-container > input {
  flex: 0 0 85%;
}

.ct-investor-actions > div .ct-input-container > button {
  background: transparent;
  color: black;
  text-decoration: underline;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-weight: lighter;
  padding-left: 10px;
}

.ct-investor-actions > div p {
  font-size: 11px;
  line-height: 10px;
  margin-top: 3px;
}

.ct-investor-actions > div ul {
  list-style: none;
  margin-top: 5px;
}

.ct-investor-actions > div ul > li {
  font-size: 9px;
  text-transform: uppercase;
}

.ct-investor-actions > div > .ct-deactivated-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(192, 16, 16, 0.644);
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  text-shadow: 5px 5px 10px #1b1b1b;
}

.ct-dynamic-pool-container .ct-charts > div {
  height: 200px;
  padding: 10px 10px 30px 10px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  border-radius: 5px;
  margin-top: 10px;
}

.ct-dynamic-pool-container .ct-charts > div > h4 {
  font-size: 14px;
  font-weight: 400;
}

.ct-testnet-pool {
  color: red;
  font-size: 14px;
}

.ct-pool-benefits {
  font-size: 14px;
  margin-bottom: 20px;
}

.ct-pool-benefits > ul {
  margin-left: 15px;
}

.ct-dynamic-pool-container > h4 {
  font-size: 32px;
  font-weight: 400;
}

.ct-dynamic-pool-container > p:nth-child(2) {
  font-size: 16px;
  margin-top: -5px;
  font-weight: bolder;
  margin-bottom: 10px;
}

.ct-dynamic-pool-container > div:nth-child(3) {
  font-size: 12px;
  margin-bottom: 20px;
}

.ct-dynamic-pool-container > div:nth-child(3) > p {
  margin-bottom: 7px;
}

.ct-dynamic-pool-container p.ct-days-to-fill {
  font-size: 14px;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
}

.ct-dynamic-pool-container p.ct-days-to-fill > small {
  list-style: circle;
  display: list-item;
  margin-left: 15px;
}

.ct-dynamic-pool-container .ct-progress-bar-container {
  width: 100%;
  background: grey;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border-radius: 5px;
}

.ct-dynamic-pool-container .ct-progress-bar-container > div {
  background: rgba(0, 238, 52, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 5px;
}

.ct-dynamic-pool-container .ct-progress-bar-container > span {
  font-size: 12px;
  color: white;
}

.ct-dynamic-pool-container .ct-investor-container > h4 {
  font-size: 25px;
  font-weight: 400;
}

.ct-dynamic-pool-container .ct-investor-container > small {
  font-weight: bold;
}

.ct-dynamic-pool-container .ct-investor-container > ul > li {
  font-size: 12px;
  margin-left: 15px;
}

.ct-dynamic-pool-container .ct-investor-container > .ct-investor-data {
  margin-top: 10px;
}

.ct-dynamic-pool-container .ct-investor-container > .ct-investor-data > ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.ct-dynamic-pool-container .ct-investor-container > .ct-investor-data > ul > li {
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  margin: 10px;
  width: 90%;
  max-width: 200px;
  padding: 0 15px;
  background: rgb(0, 172, 238);
  color: white;
  font-weight: bolder;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}

.ct-connect-to-network {
  margin: 20px 0;
  max-width: 300px;
}

.ct-connect-to-network > h4 {
  font-size: 24px;
  font-weight: 400;
}

.ct-connect-to-network > p {
  font-size: 12px;
  margin-bottom: 10px;
}

/* Pool injections */

.ct-dynamic-injected-transactions > h4 {
  margin-bottom: 15px;
}

.ct-dynamic-injected-transactions {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ct-dynamic-injected-transactions > div {
  display: flex;
  border-bottom: 1px solid rgba(128, 128, 128, 0.466);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  padding: 5px 0;
  align-items: center;
  margin-bottom: 10px;
  background: white;
  position: relative;
}

.ct-dynamic-injected-transactions > div > .ct-type {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  flex: 0 0 15%;
  min-width: 70px;
  height: 100%;
}

.ct-dynamic-injected-transactions > div > .ct-type.ct-injection-type {
  background: rgba(123, 247, 123, 0.616);
}

.ct-dynamic-injected-transactions > div > div {
  margin: 7px;
  font-weight: lighter;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}

.ct-dynamic-injected-transactions > div > .ct-data {
  flex: 0 0 70%;
  min-width: 160px;
  align-items: flex-start;
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
}

.ct-dynamic-injected-transactions > div > .ct-actions {
  flex: 0 0 15%;
  min-width: 70px;
}

html {
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}
ct-circles-animation body {
  max-width: 100vw;
  background: rgb(255, 255, 255);
  overflow-x: hidden;
}

.ct-app-container {
  width: 100%;
  position: relative;
}

.ct-max-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Buttons                               */
/* -------------------------------------------------------------------------- */

.animate__delay-0-5s {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.animate__delay-1-5s {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

.animate__delay-2-5s {
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}

.animate__delay-3-5s {
  -webkit-animation-delay: 3.5s;
          animation-delay: 3.5s;
}

.ct-main-button {
  transition: all 0.2s ease;
  background: black;
  border: 0;
  color: white;
  padding: 0 15px;
  font-weight: bold;
  cursor: pointer;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  height: 30px;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 10px;
}

.ct-main-button:disabled:hover {
  background: #ef9a9a;
}

.ct-main-button.ct-active {
  background: #7e7e7e;
}

.ct-main-button:hover {
  background: #7e7e7e;
}

.btn-2 {
  background: rgb(0, 172, 238);
  text-transform: uppercase;
  height: 30px;
  width: 100%;
  margin-top: 5px;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Margins                               */
/* -------------------------------------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
}

.ct-mb-1 {
  margin-bottom: 1px;
}

.ct-mb-2 {
  margin-bottom: 2px;
}

.ct-mb-20 {
  margin-bottom: 20px !important;
}

.ct-mb-5 {
  margin-bottom: 5px;
}

.ct-pt-30 {
  padding-top: 30px;
}

.ct-pt-20 {
  padding-top: 20px;
}

.ct-pb-10 {
  padding-bottom: 10px;
}

.ct-mt-5 {
  margin-top: 5px;
}

.ct-mt-10 {
  margin-top: 10px;
}

.ct-mt-20 {
  margin-top: 20px;
}

.ct-mt-50 {
  margin-top: 50px;
}

.ct-mr-5 {
  margin-right: 5px !important;
}

.ct-mr-10 {
  margin-right: 10px !important;
}

.ct-ml-5 {
  margin-left: 5px !important;
}

.ct-ml-10 {
  margin-left: 10px !important;
}

a {
  font-weight: bold;
  color: black;
}

.ct-title {
  display: flex;
  flex-direction: column;
}

.ct-title > span:nth-child(2) {
  font-size: 10px;
  font-weight: bold;
}

input {
  text-transform: uppercase;
  font-size: 10px;
}

input,
input:hover,
input:active,
input:focus {
  outline: none;
}

textarea,
textarea:focus,
textarea:active {
  border: 0;
  border-bottom: 1px solid black;
  outline: none;
  padding: 10px;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Comming                               */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Spinners                               */
/* -------------------------------------------------------------------------- */

.spinner {
  width: 40px;
  height: 40px;
  background-color: #333;
  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.Toastify {
  z-index: 999999999999 !important;
}

.Toastify__toast {
  z-index: 999999999999 !important;
}

/* -------------------------------------------------------------------------- */
/*                            ANCHOR Universal Minter                         */
/* -------------------------------------------------------------------------- */

button {
  background-color: black;
  color: white;
  border: 0;
  border-radius: 3px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  height: 25px;
}

button:disabled {
  background: rgb(211, 89, 89);
}

.ct-page-header {
  position: relative;
  margin-bottom: 20px;
}

input {
  border: 0;
  border-bottom: 1px solid black;
  height: 30px;
  text-align: center;
  background: transparent;
}

input:focus,
input:active,
input:hover {
  background-color: transparent;
}

/* Material UI */

.MuiSlider-colorSecondary {
  color: black;
}

/* Main web page */

.ct-platforms-banner,
.ct-info-container {
  display: flex;
  justify-content: center;
  color: rgb(255, 255, 255);
  align-items: center;
  flex-direction: row;
  padding: 50px;
  margin-bottom: 50px;
  flex-wrap: wrap;
}

.ct-platforms-banner > .ct-info,
.ct-info-container > .ct-info {
  max-width: 300px;
  margin: 10px;
  padding: 10px;
}

.ct-platforms-banner > .ct-info > h4,
.ct-info-container > .ct-info > h4 {
  font-size: 30px;
  font-family: "Plaguard";
  margin-bottom: 10px;
}

.ct-platforms-banner > .ct-info > p a {
  color: white;
}

.ct-platforms-banner > .ct-info > div,
.ct-info-container > .ct-info > div {
  display: flex;
  margin-top: 15px;
  flex-direction: row;
}

.ct-platforms-banner > .ct-info > div > span,
.ct-info-container > .ct-info > div > span {
  font-size: 30px;
  margin: 0 10px;
  width: 40px;
  height: 40px;
}

.ct-platforms-banner > .ct-info > div > img,
.ct-info-container > .ct-info > div > img {
  width: 40px;
  height: 40px;
}

.ct-platforms-banner > .ct-info > button,
.ct-info-container > .ct-info > button {
  margin-top: 15px;
  max-width: 200px;
}

.ct-cards-show {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ct-cards-show .ct-header {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 10px;
  width: 90%;
}

.ct-cards-show .ct-header h4 {
  font-family: "Plaguard";
  font-size: 40px;
}

.ct-cards-show .ct-header small {
  font-size: 15px;
}

.ct-cards-show .ct-deck-container {
  margin-bottom: 50px;
}

/* Profile page */

.ct-profile-page {
  padding-top: 50px;
}

/* Principal Button */

.btn-3 {
  color: #fff;
  background: rgb(0, 172, 238);
  background: linear-gradient(0deg, rgba(0, 172, 238, 1) 0%, rgba(2, 126, 251, 1) 100%);
  width: 130px;
  height: 40px;
  line-height: 42px;
  padding: 0;
  border: none;
  font-weight: 500;
  font-family: "Lato", sans-serif;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5), 7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
  text-shadow: 1px 1px 2px rgb(19 19 19);
  text-transform: uppercase;
}
.btn-3 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-3:before,
.btn-3:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background: rgba(2, 126, 251, 1);
  transition: all 0.3s ease;
}
.btn-3:before {
  height: 0%;
  width: 2px;
}
.btn-3:after {
  width: 0%;
  height: 2px;
}
.btn-3:hover {
  background: transparent;
  box-shadow: none;
}
.btn-3:hover:before {
  height: 100%;
}
.btn-3:hover:after {
  width: 100%;
}
.btn-3 span:hover {
  color: white;
}
.btn-3 span:before,
.btn-3 span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: rgba(2, 126, 251, 1);
  transition: all 0.3s ease;
}
.btn-3 span:before {
  width: 2px;
  height: 0%;
}
.btn-3 span:after {
  width: 0%;
  height: 2px;
}
.btn-3 span:hover:before {
  height: 100%;
}
.btn-3 span:hover:after {
  width: 100%;
}

.ct-test-container {
  height: 500px;
}

/* pools page */

.ct-pool-page .ct-available-pools {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgba(250, 250, 250, 0.699);
  padding-bottom: 40px;
}

.ct-pool-page .ct-available-pools > .ct-header {
  display: flex;
  flex-direction: column;
}

.ct-pool-page .ct-available-pools > .ct-header h4 {
  font-size: 25px;
  margin-bottom: -3px;
  font-weight: 400;
}

.ct-pool-page .ct-available-pools > .ct-pools-list {
  margin-top: 20px;
}

.ct-blue-gradient-top {
  background: rgb(17, 88, 166);
  background: linear-gradient(180deg, rgba(17, 88, 166, 0) 0%, rgba(9, 142, 240, 1) 66%);
  height: 400px;
}

.ct-blue-gradient-bottom {
  background: rgb(17, 88, 166);
  background: linear-gradient(0deg, rgba(118, 123, 128, 0) 0%, rgba(9, 142, 240, 1) 66%);
  height: 400px;
}

/* Circles animation */

.ct-circles-animation {
  position: fixed;
  top: 0;
  left: -150;
  width: 150%;
  height: 100%;
  overflow: hidden;
  z-index: -10;
}

.ct-circles-animation li {
  position: fixed;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #098efc;
  -webkit-animation: animate 25s linear infinite;
          animation: animate 25s linear infinite;
  bottom: -150px;
}

.ct-circles-animation li:nth-child(1) {
  left: 15%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.ct-circles-animation li:nth-child(2) {
  left: 5%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 12s;
          animation-duration: 12s;
}

.ct-circles-animation li:nth-child(3) {
  left: 90%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}

.ct-circles-animation li:nth-child(4) {
  left: 84%;
  width: 60px;
  height: 60px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
}

.ct-circles-animation li:nth-child(5) {
  left: 85%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.ct-circles-animation li:nth-child(6) {
  left: 0%;
  width: 110px;
  height: 110px;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

.ct-circles-animation li:nth-child(7) {
  left: 75%;
  width: 150px;
  height: 150px;
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}

.ct-circles-animation li:nth-child(8) {
  left: 90%;
  width: 25px;
  height: 25px;
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
  -webkit-animation-duration: 45s;
          animation-duration: 45s;
}

.ct-circles-animation li:nth-child(9) {
  left: 12%;
  width: 15px;
  height: 15px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 35s;
          animation-duration: 35s;
}

.ct-circles-animation li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 11s;
          animation-duration: 11s;
}

.ct-circles-animation li:nth-child(11) {
  left: 50%;
  width: 250px;
  height: 250px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 11s;
          animation-duration: 11s;
}

.ct-circles-animation li:nth-child(12) {
  left: 30%;
  width: 100px;
  height: 100px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 11s;
          animation-duration: 11s;
}

.ct-circles-animation li:nth-child(13) {
  left: 40%;
  width: 200px;
  height: 200px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 9s;
          animation-duration: 9s;
}

@-webkit-keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
            transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

@keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
            transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

/* Custom */

/* CSS */
.button-29 {
  align-items: center;
  -webkit-appearance: none;
          appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #41aaff 0, #098efc 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 38px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, -webkit-transform 0.15s;
  transition: box-shadow 0.15s, transform 0.15s;
  transition: box-shadow 0.15s, transform 0.15s, -webkit-transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  max-width: 200px;
  text-transform: uppercase;
  font-size: 12px;
}

.button-29.button-small {
  max-width: 150px;
  height: 30px;
}

.button-29:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.button-29 a {
  color: white;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Dashboard */

.ct-dashboard-list {
  margin-top: 80px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgba(250, 250, 250, 0.699);
  padding-bottom: 40px;
}

.ct-dashboard-list > .ct-charts-container {
  margin-bottom: 20px;
}

.ct-dashboard-list > .ct-charts-container .ct-treemap-chart {
  width: 100%;
  height: 200px;
}

.ct-investment-recomendation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;
}

.ct-investment-recomendation > h5 {
  font-size: 18px;
}

.ct-investment-recomendation > p {
  font-size: 14px;
}

.ct-road-map-page {
  margin-top: 100px;
}


.ct-main-sliders-container .ct-project-description {
  background: white;
  color: black;
  display: flex;
  overflow: hidden;
  margin-bottom: 100px;
  flex-direction: column;
  margin-top: -30px;
  width: 90%;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  transition: all 0.2s ease;
}

.ct-main-sliders-container .ct-project-description:hover {
  box-shadow: 0 7px 14px rgb(0 0 0 / 25%), 0 5px 5px rgb(0 0 0 / 22%);
}

.ct-main-sliders-container .ct-project-description .ct-image-container img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
  min-height: 200px;
  max-height: 400px;
}

.ct-main-sliders-container .ct-project-description .ct-profit-container {
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 200px;
  max-height: 400px;
  padding: 20px;
  flex-direction: column;
  position: relative;
}

.ct-main-sliders-container .ct-project-description .ct-profit-container h4 {
  font-size: 35px;
  z-index: 10;
  color: rgba(9, 142, 240, 1);
  text-shadow: 1px 1px 2px rgba(19, 19, 19, 0.438);
}

.ct-main-sliders-container .ct-project-description .ct-profit-container > small {
  font-weight: bold;
  margin-bottom: 10px;
  z-index: 10;
}

.ct-main-sliders-container .ct-project-description .ct-profit-container p {
  z-index: 10;
}

.ct-main-sliders-container .ct-project-description .ct-profit-container button {
  z-index: 10;
}

.ct-main-sliders-container .ct-project-description .ct-profit-container .ct-chart-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 120%;
  height: 100%;
}

@media screen and (min-width: 900px) {
  .ct-main-sliders-container .ct-project-description {
    flex-direction: row;
    height: 400px;
  }

  .ct-main-sliders-container .ct-project-description > div:nth-child(1) {
    flex: 0 0 40%;
    max-width: 40%;
    overflow: hidden;
  }

  .ct-main-sliders-container .ct-project-description > div:nth-child(2) {
    flex: 0 0 60%;
    max-width: 60%;
    overflow: hidden;
  }

  .ct-main-sliders-container .ct-project-description .ct-profit-container {
    max-height: auto;
    width: 100%;
    min-height: auto;
  }
}
